<template>
  <form>
    <h5 class="text-center">
      This table shows information about all the customers we have in the
      system.
    </h5>
    <h5 class="text-center">
      Please choose a customer from the table by clicking on it, to update it's
      information.
    </h5>
    <div class="row d-flex justify-content-center">
      <div class="col-md-12">
        <card title="Customers">
          <div class="col-md-12">
            <div class="row">
              <pg-table
                :tableColumns="PgTable.tableColumns"
                :tableData="PgTable.tableData"
                :propsToSearch="PgTable.propsToSearch"
                :handleCurrentChange="PgTable.handleCurrentChange"
                :withActions="true"
                @on-deleteRow="deleted = true"
                :paginated="true"
                :searchable="true"
              >
              </pg-table>
            </div>
          </div>
        </card>
        <!-- end card -->
      </div>
    </div>
  </form>
</template>
<script>
import { Select, Option } from "element-ui";
import { initAPI } from "src/gateway/api-instance.js";
import PgTable from "src/pages/Dashboard/Tables/PaginatedTables.vue";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PgTable
  },
  data() {
    return {
      PgTable: {
        tableColumns: [
          {
            prop: "name",
            label: "Name",
            minWidth: 70
          },
          {
            prop: "address",
            label: "Address",
            minWidth: 150
          },
          {
            prop: "country",
            label: "Country",
            minWidth: 90
          },
          {
            prop: "city",
            label: "City",
            minWidth: 50
          },
          {
            prop: "state",
            label: "State",
            minWidth: 75
          },
          {
            prop: "zipCode",
            label: "Zip Code",
            minWidth: 75
          },
          {
            prop: "numOfVenues",
            label: "No.Venues",
            minWidth: 80
          },
          {
            prop: "status",
            label: "Status",
            minWidth: 70
          }
        ],
        tableData: [],
        propsToSearch: [
          "name",
          "country",
          "city",
          "state",
          "zipCode",
          "status"
        ],
        handleCurrentChange: this.customerSelection
      },
      deleted: false
    };
  },
  mounted() {
    this.getCustomers();
  },
  computed: {
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit("on-validated", res);
        return res;
      });
    },
    getCustomers() {
      this.Instance.get("/customers").then(response => {
        this.PgTable.tableData = response.data;
        this.PgTable.tableData.forEach((element, index) => {
          this.$set(
            this.PgTable.tableData[index],
            "numOfVenues",
            element.venues.length
          );
          element.venues.forEach(venue => {
            venue.venueInfo = {
              name: venue.name,
              email: venue.email,
              address: venue.address,
              country: venue.country,
              city: venue.city,
              state: venue.state,
              zipCode: venue.zipCode,
              venueType: venue.type,
              venueStatus: venue.venueStatus,
              customer_ID: venue.customer_ID,
              assets: venue.assets,
              barStations: venue.barStations,
              id: venue.id,
              posData: venue.posData,
              posPatch: venue.posPatch,
              venueDrink: venue.venueDrink,
              pos_SYSTEM_ID: venue.pos_SYSTEM_ID,
              service_CONTACT_ID: venue.service_CONTACT_ID,
              primary_CONTACT_ID: venue.primary_CONTACT_ID,
              time_ZONE: venue.time_ZONE,
              var_ID: venue.var_ID,
              venueBusinessDates: venue.venueBusinessDates,
              venuePourStandardSizes: venue.venuePourStandardSizes
            };
            venue.customer = element.name;
            venue.venueName = venue.name;
            venue.venueStatus = venue.venueStatus;
            venue.posSystem = venue.posCredentials.posSystem;

            var cred = venue.posCredentials.credentials.split(",");
            if (venue.posCredentials.posSystem === "InfoGenesis") {
              venue.posCredentials.IGpath = cred[0];
            } else if (venue.posCredentials.posSystem === "Shift4") {
              venue.posCredentials.Sh4ClientID = cred[0];
              venue.posCredentials.Sh4ClientSecret = cred[1];
            } else {
              venue.posCredentials.ClMerchantID = cred[0];
              venue.posCredentials.ClAccessToken = cred[1];
              venue.posCredentials.ClHost = cred[2];
            }

            this.$delete(venue, "name");
            this.$delete(venue, "email");
            this.$delete(venue, "address");
            this.$delete(venue, "country");
            this.$delete(venue, "city");
            this.$delete(venue, "type");
            this.$delete(venue, "state");
            this.$delete(venue, "zipCode");
            this.$delete(venue, "type");
            this.$delete(venue, "customer_ID");
            this.$delete(venue, "assets");
            this.$delete(venue, "id");
            this.$delete(venue, "barStations");
            this.$delete(venue, "posData");
            this.$delete(venue, "posPatch");
            this.$delete(venue, "venueDrink");
            this.$delete(venue, "pos_SYSTEM_ID");
            this.$delete(venue, "service_CONTACT_ID");
            this.$delete(venue, "primary_CONTACT_ID");
            this.$delete(venue, "time_ZONE");
            this.$delete(venue, "var_ID");
            this.$delete(venue, "venueBusinessDates");
            this.$delete(venue, "venuePourStandardSizes");
          });
        });
        console.log("customers ", this.PgTable.tableData);
      });
    },
    customerSelection(val) {
      if (this.deleted == false) {
        this.currentRow = val;
        console.log("customer table selected row ", val);
        this.$emit("fireCustomerEditing", val);
      }
      this.deleted = false;
    }
  }
};
</script>
<style>
.custom-button {
  font-family: Arial;
  font-size: 1.005rem;
  text-align: left;
  margin: 1;
}
.custom-icon {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  margin: 1;
}
</style>
